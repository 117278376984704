import { Injectable } from '@angular/core';
import { CookieService as NgxCookieService } from '@local-patches/ngx-cookie';
import { interval } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { State } from './cookie.state';
import * as CookieActions from './cookie.actions';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { isEqual } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class CookieService {
  constructor(
    private ngxCookieService: NgxCookieService,
    private store: Store<State>,
  ) {
    interval(500)
      .pipe(
        map(() => this.ngxCookieService.getAll()),
        distinctUntilChanged(isEqual),
      )
      .subscribe(cookies => {
        this.store.dispatch(new CookieActions.SetCookies(cookies));
      });
  }

  cookieGet(name: string) {
    return this.store.pipe(select(state => state.cookie[name]));
  }
}
