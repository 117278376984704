import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-found-404',
  template: `
    <p>
      not-found404 works!
    </p>
  `,
  styles: [],
})
export class NotFound404Component implements OnInit {
  constructor() {}

  ngOnInit() {}
}
