import { Injectable } from '@angular/core';
import { Apollo as OrigApollo, QueryRef } from 'apollo-angular';
import {
  distinctUntilChanged,
  skip,
  switchMap,
  tap,
  map,
} from 'rxjs/operators';
import { DocumentNode } from 'graphql';
import stringify from 'json-stable-stringify';
import { Subscription, Observable, interval, from } from 'rxjs';
import { ApolloQueryResult } from 'apollo-client';
import { isEqual } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class Apollo extends OrigApollo {
  protected pollingKeyCache = new Map<string, [Subscription, Subscription?]>();
  protected pollingKeyFreq = new Map<string, number[]>();
  protected queryKeyCache = new Map<string, number>();
  protected callbackCache = new Map<string, Map<string, () => void>>();

  pollingQuery<T, V>({
    pollingQuery,
    query,
    freq = 5000,
  }: {
    pollingQuery: QueryRef<any, any>;
    query: QueryRef<T, V>;
    freq?: number;
  }): Observable<ApolloQueryResult<T>> {
    const pollingKey = stringify(pollingQuery['obsQuery'].options.query);
    const queryKey = stringify(query['obsQuery'].options.query);

    return new Observable(observer => {
      let callbackCache = this.callbackCache.get(pollingKey);

      if (!callbackCache) {
        callbackCache = new Map();

        const sub = pollingQuery.valueChanges
          .pipe(
            map(value => value!.data),
            distinctUntilChanged(isEqual),
            skip(1),
          )
          .subscribe(() => {
            for (const fn of callbackCache!.values()) {
              fn();
            }
          });

        this.pollingKeyCache.set(pollingKey, [sub]);
        this.pollingKeyFreq.set(pollingKey, []);
        this.callbackCache.set(pollingKey, callbackCache);
      }

      if (!callbackCache.has(queryKey)) {
        const callback = (() => query.refetch()).bind(this);
        callbackCache.set(queryKey, callback);
        this.queryKeyCache.set(queryKey, 1);
      }

      this.queryKeyCache.set(queryKey, this.queryKeyCache.get(queryKey)! + 1);

      const freqs = this.pollingKeyFreq.get(pollingKey)!;
      const oldFreq = freqs[0];
      freqs.push(freq);
      freqs.sort();

      this.calculatePollingInterval(oldFreq, freqs, pollingQuery, pollingKey);

      return query.valueChanges.subscribe(observer).add(() => {
        /**
         * Cleanup isn't working as intended, causing a memory leak
         */
        // console.log(
        //   'unsubscribe',
        //   this.pollingKeyCache,
        //   this.pollingKeyFreq,
        //   this.queryKeyCache,
        //   this.callbackCache,
        // );

        const count = this.queryKeyCache.get(queryKey)!;

        if (count > 1) {
          this.queryKeyCache.set(queryKey, count - 1);
          const index = freqs.findIndex(val => val === freq);
          freqs.splice(index, 1);
          freqs.sort();
          const oldFreq = freqs[0];
          this.calculatePollingInterval(
            oldFreq,
            freqs,
            pollingQuery,
            pollingKey,
          );
          return;
        }

        this.queryKeyCache.delete(queryKey);

        const callbackMap = this.callbackCache.get(pollingKey)!;

        callbackMap.delete(queryKey);

        if (callbackMap.size > 0) return;

        this.pollingKeyCache
          .get(pollingKey)!
          .forEach(sub => sub!.unsubscribe());
        this.pollingKeyCache.delete(pollingKey);
        this.callbackCache.delete(pollingKey);
      });
    });
  }

  protected calculatePollingInterval(
    oldFreq: number,
    freqs: number[],
    pollingQuery: QueryRef<any, any>,
    pollingKey: string,
  ) {
    const newFreq = freqs[0];

    if (newFreq !== oldFreq) {
      // for some reason apollo polling isn't working
      const sub1 = interval(newFreq)
        .pipe(switchMap(() => from(pollingQuery.refetch())))
        .subscribe();

      const subs = this.pollingKeyCache.get(pollingKey)!;

      if (subs[1]) subs[1].unsubscribe();

      subs[1] = sub1;
    }
  }
}
