import { ActionReducerMap } from '@ngrx/store';
import * as fromCookie from './cookie.reducer';

export interface State {
  cookie: fromCookie.State;
}

export const reducers: ActionReducerMap<State, any> = {
  cookie: fromCookie.reducer,
};
