import { Action } from '@ngrx/store';
import { Cookie } from './cookie.model';

export enum CookieActionTypes {
  SET_COOKIES = '[Cookie] Set all cookies',
  CLEAR_COOKIES = '[Cookie] Clear all cookies',
  SET_COOKIE = '[Cookie] Set cookie',
  REMOVE_COOKIE = '[Cookie] Remove cookie',
}

export class SetCookies implements Action {
  readonly type = CookieActionTypes.SET_COOKIES;

  constructor(public payload: { [name: string]: Cookie }) {}
}

export class ClearCookies implements Action {
  readonly type = CookieActionTypes.CLEAR_COOKIES;
}

export class SetCookie implements Action {
  readonly type = CookieActionTypes.SET_COOKIE;

  constructor(public payload: { name: string; value: Cookie }) {}
}

export class RemoveCookie implements Action {
  readonly type = CookieActionTypes.REMOVE_COOKIE;

  constructor(public payload: { name: string }) {}
}

export type CookieActions =
  | SetCookies
  | ClearCookies
  | SetCookie
  | RemoveCookie;
