<mat-progress-bar
  *ngIf="('default' | swIsLoading | async)"
  mode="indeterminate"
  color="warn"
  style="position: absolute; top: 0; z-index: 5000;"
>
</mat-progress-bar>

<router-outlet></router-outlet>

<!-- <header *ngIf="user"
    class='mat-elevation-z8'
    style='position: sticky; top: 0px;'
    >
  <mat-toolbar>
    <mat-toolbar-row app-platform-toolbar [user]='user' (menu)='toggleMenu()'></mat-toolbar-row>
  </mat-toolbar>
  <lib-info-bar *ngIf='displayNotYouBar'>
    <div class='close-wrapper'><mat-icon (click)='displayNotYouBar = false'>close</mat-icon></div>
    <div>
      <p>Not you? Click the <mat-icon>menu</mat-icon> icon in the upper right.</p>
    </div>
  </lib-info-bar>
</header> -->

<!-- <mat-sidenav-container>
  <mat-sidenav #sideNav position="end" mode="over" [ngSwitch]="menuView">
    <nav *ngSwitchCase="'signed in'"> -->
<!-- <li><a mat-button routerLink="person/{{user?.id}}/profile" (click)='sideNav.close()'><mat-icon>account_box</mat-icon> Profile</a></li> -->
<!-- <li><a mat-button><mat-icon>local_hospital</mat-icon> Emergency Info</a></li> -->
<!-- <li><a mat-button><mat-icon style='font-size: 22px;'>pan_tool</mat-icon> Skills</a></li> -->
<!-- <li><a mat-button><mat-icon>access_time</mat-icon> Availability</a></li> -->
<!-- <li><a mat-button><mat-icon svgIcon='sw:icon-black'></mat-icon> Opportunities</a></li> -->
<!-- <li><a mat-button><mat-icon>school</mat-icon> Trainings</a></li> -->
<!-- <li><a mat-button><mat-icon>business</mat-icon> Organizations</a></li> -->
<!-- <li><a mat-button><mat-icon>settings</mat-icon> Settings</a></li> -->
<!-- <li>
        <button
          mat-button
          type="button"
          color="warn"
          (click)="signout(sideNav)"
        >
          <mat-icon>person_outline</mat-icon> Signout
        </button>
      </li>
    </nav>

    <ng-container *ngSwitchCase="'verified'">
      <div class="highlight padding-24">
        <p>
          If you are not {{ user?.identifier }},
          <a (click)="unloadUserAccount()">click here</a>.
        </p>
      </div>

      <div class="padding-24">
        <p>
          Hi {{ user?.identifier }}, <br /><br />
          You need to sign in before you can view your profile.
        </p>

        <a
          mat-button
          [href]="signinUrl"
          class="sw-button submit"
          style="margin-top: 1rem"
        >
          Sign in
        </a>
      </div>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <div class="highlight padding-24">
        <p>
          If you are not {{ user?.identifier }},
          <a (click)="unloadUserAccount()">click here</a>.
        </p>
      </div> -->

<!-- <div class='padding-24'>
        <p>
          Hi {{user?.identifier}},
          <br><br>
          Your account is currently not secured with a password.
          <br><br>
          To view and update your volunteer profile, we'll need to send you a verification email.
        </p>

        <button mat-button type='button' class='sw-button submit' style='margin-top: 1rem'>Send Verification Email</button>
      </div> -->
<!-- </ng-container>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container> -->

<!-- <note-notification></note-notification> -->
