import { Injectable } from '@angular/core';
import {
  SnotifyService,
  SnotifyToastConfig,
  SnotifyPosition,
} from '@local-patches/ng-snotify';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private snotifyService: SnotifyService) {}

  errorMessage(message: string, options: { title?: string } = {}) {
    const config: SnotifyToastConfig = {
      position: SnotifyPosition.leftBottom,
      timeout: 5000,
    };

    const title = options.title ? options.title : 'Error';

    this.snotifyService.error(message, title, config);
  }
}
