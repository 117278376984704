import { SnotifyToastConfig } from '../interfaces/SnotifyToastConfig.interface';
import { Subject, Subscription } from 'rxjs';
import { SnotifyEvent } from '../types/event.type';
import { SnotifyStyle } from '../enums/SnotifyStyle.enum';
import { ProcessedSnotifyToastConfig } from '../interfaces/ProcessedSnotifyDefaults.interface';
// @TODO remove method in observable way
/**
 * Toast main model
 */
export class SnotifyToast {
  /**
   * Emits {SnotifyEvent}
   */
  readonly eventEmitter = new Subject<SnotifyEvent>();
  /**
   * Holds all subscribers because we need to unsubscribe from all before toast get destroyed
   */
  private _eventsHolder: Subscription[] = [];
  /**
   * Toast prompt value
   */
  value: string;
  /**
   * Toast validator
   */
  valid: boolean;
  constructor(
    public id: number,
    public title: string | undefined,
    public body: string | undefined,
    public config: ProcessedSnotifyToastConfig
  ) {
    if (this.config.type === SnotifyStyle.prompt) {
      this.value = '';
    }
    this.on('hidden', () => {
      this._eventsHolder.forEach((subscription: Subscription) => {
        subscription.unsubscribe();
      });
    });
  }

  /**
   * Subscribe to toast events
   * @param event
   * @param action
   */
  on(event: SnotifyEvent, action: (toast: this) => void): this {
    this._eventsHolder.push(
      this.eventEmitter.subscribe((e: SnotifyEvent) => {
        if (e === event) {
          action(this);
        }
      })
    );
    return this;
  }
}
