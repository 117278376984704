import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-info-bar',
  template: `
    <ng-content></ng-content>
  `,
  styleUrls: ['./info-bar.component.scss'],
  host: { class: 'sw-info-bar' },
})
export class InfoBarComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
