import { CookieActions, CookieActionTypes } from './cookie.actions';
import { Cookie } from './cookie.model';
import produce from 'immer';

export interface State {
  [cookieName: string]: Cookie;
}

export const initialState: State = {};

export function reducer(state = initialState, action: CookieActions) {
  return produce(state, draft => {
    switch (action.type) {
      case CookieActionTypes.SET_COOKIES:
        return action.payload;
      case CookieActionTypes.CLEAR_COOKIES:
        return initialState;
      case CookieActionTypes.SET_COOKIE:
        draft[action.payload.name] = action.payload.value;
        return;
      case CookieActionTypes.REMOVE_COOKIE:
        delete draft[action.payload.name];
        return;
      default:
        return state;
    }
  });
}
