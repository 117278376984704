import { Inject, Injectable, InjectionToken, Injector, Optional } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';

import { CookieOptions } from './cookie-options.model';
import { mergeOptions } from './utils';

export const COOKIE_OPTIONS = new InjectionToken<CookieOptions>('COOKIE_OPTIONS');

@Injectable({
  providedIn: 'root',
})
export class CookieOptionsProvider {
  readonly options: CookieOptions;

  constructor(
    @Optional() @Inject(COOKIE_OPTIONS) providedOptions: CookieOptions = {},
    private _injector: Injector
  ) {
    const defaultOptions: any = {
      path: this._injector.get(APP_BASE_HREF, '/'),
      domain: null,
      expires: null,
      secure: false,
      httpOnly: false,
    };

    this.options = mergeOptions(defaultOptions, providedOptions);
  }
}
