import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { ProfilePictureComponent } from './profile-picture/profile-picture.component';

export { ProfilePictureComponent };

@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule],
  declarations: [ProfilePictureComponent],
  exports: [ProfilePictureComponent],
})
export class ProfilePictureModule {}
