import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SnotifyModule, ToastDefaults } from '@local-patches/ng-snotify';

import { NotificationService } from './notification.service';
import { NotificationComponent } from './notification.component';

export { NotificationService };

@NgModule({
  imports: [CommonModule, SnotifyModule],
  providers: [{ provide: 'SnotifyToastConfig', useValue: ToastDefaults }],
  declarations: [NotificationComponent],
  exports: [NotificationComponent]
})
export class NotificationModule {}
