import { SharedEnvironment } from './environment';

const graphql = 'https://dev-api.service.work/v1/graphql';
const functions = 'https://us-central1-service-work-dev.cloudfunctions.net';
const accountClient = 'https://accounts.dev.service.work';
const staffClient = 'https://staff.dev.service.work';
const staffMarketing = 'https://about.dev.service.work';
const volunteerClient = 'https://dev.service.work';

export const sharedEnvironment: SharedEnvironment = {
  production: false,
  uris: {
    graphql,
    functions,
    staffClient,
    volunteerClient,
    accountClient,
    staffMarketing,
  },
  facebookShared: {
    appId: '266437040941667',
  },
  googleShared: {
    reCaptchaKey: '6LfuZ5UUAAAAAEt6bdJsgVm3pksj8DJTbjqmh8id',
  },
  mapboxShared: {
    accessToken:
      'pk.eyJ1IjoiamNhcnJvbGwiLCJhIjoiY2p1eHczbnIzMHJtajQzcHAxNHAwcjk4cyJ9.gWRlR0rRFWnFJnH9z-QZjg',
  },
  platformId: '479969bf-67c5-49b6-bad8-abfe43f57b76',
};
