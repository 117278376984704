export const dataIdFromObject = (o: any) => {
  switch (o.__typename) {
    case 'PersonSensitiveProfile':
      return o.__typename + o.personProfileId;
    case 'OpportunityAddress':
      return o.__typename + o.opportunityId;
    default:
      return o.id && o.__typename + '-' + o.id;
  }
};
