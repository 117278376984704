import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sw-logo',
  template: `
    <mat-icon
      style="width: {{ logoSize }}px; height: {{ logoSize }}px;"
      svgIcon="sw:logo-black"
    ></mat-icon>
    <h5>Nonprofit Center</h5>
  `,
  styleUrls: ['./sw-logo.component.scss'],
  host: { class: 'sw-logo' },
})
export class SWLogoComponent implements OnInit {
  @Input() logoSize: number;

  constructor() {}

  ngOnInit() {}
}
