import { InjectionToken } from '@angular/core';

export const ENVIRONMENT = new InjectionToken('Environment');

export interface IEnvironment extends SharedEnvironment {
  name:
    | 'staffClient'
    | 'volunteerClient'
    | 'accountClient'
    | 'staffMarketing'
    | 'communicationClient';
  firebase: {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
  };
  google?: {
    clientId: string;
  };
}

export interface SharedEnvironment {
  production: boolean;
  uris: {
    graphql: string;
    functions: string;
    staffClient: string;
    volunteerClient: string;
    accountClient: string;
    staffMarketing: string;
  };
  facebookShared: {
    appId: string;
  };
  googleShared: {
    reCaptchaKey: string;
  };
  mapboxShared: {
    accessToken: string;
  };
  platformId: string;
}
