import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SnotifyComponent } from './snotify.component';
import { ToastComponent } from './toast/toast.component';
import { SnotifyService } from './snotify.service';
import { TruncatePipe } from './pipes/truncate.pipe';
import { ButtonsComponent } from './toast/button/buttons.component';
import { PromptComponent } from './toast/prompt/prompt.component';
import { KeysPipe } from './pipes/keys.pipe';

export * from './snotify.component';
export * from './snotify.service';
export * from './interfaces/Snotify.interface';
export * from './interfaces/SnotifyButton.interface';
export * from './interfaces/SnotifyToastConfig.interface';
export * from './interfaces/SnotifyNotifications.interface';
export * from './enums/SnotifyPosition.enum';
export * from './toast/snotify-toast.model';
export * from './toast/toast.component';
export * from './pipes/truncate.pipe';
export * from './pipes/keys.pipe';

export * from './toast/button/buttons.component';
export * from './toast/prompt/prompt.component';
export * from './toastDefaults';

@NgModule({
  imports: [CommonModule],
  declarations: [
    SnotifyComponent,
    ToastComponent,
    TruncatePipe,
    ButtonsComponent,
    PromptComponent,
    KeysPipe,
  ],
  exports: [SnotifyComponent, TruncatePipe, KeysPipe],
})
export class SnotifyModule {}
