import {
  Component,
  Inject,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  ElementRef,
  PLATFORM_ID,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'lib-profile-picture',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container [ngSwitch]="loading">
      <div *ngSwitchCase="true" class="flex-center">
        <mat-spinner [diameter]="32"></mat-spinner>
      </div>

      <ng-container *ngSwitchDefault>
        <!-- for some reason it forces me to use [ngStyle] to set 'z-index' ... -->
        <img
          *ngFor="let url of photoUrls; index as i; trackBy: trackByUrl"
          src="{{ url }}"
          [ngStyle]="{
            'z-index': photoUrls.length - i,
            'margin-left': _leftMargin
          }"
        />
      </ng-container>
    </ng-container>
  `,
  styleUrls: ['./profile-picture.component.scss'],
  host: { class: 'profile-picture' },
  encapsulation: ViewEncapsulation.None,
})
export class ProfilePictureComponent implements OnInit {
  private _photoUrls: string[] = [];
  @Input()
  set photoUrls(value: any) {
    if (!value) this._photoUrls = [];
    else if (typeof value === 'string') {
      this._photoUrls = [value];
    } else {
      this._photoUrls = value;
    }
  }
  get photoUrls() {
    return this._photoUrls;
  }

  @Input() loading = false;

  public _leftMargin = '';

  trackByUrl = (index: number, url: string) => url;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private el: ElementRef,
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this._leftMargin = `-${(this.el.nativeElement as HTMLDivElement)
        .offsetHeight / 1.5}px`;
    } else {
      this._leftMargin = '-1rem';
    }
  }
}
