import { Action } from '@ngrx/store';

export const SET_STATE = '[Stable Router] Set State' as string;

export class SetState implements Action {
  readonly type = SET_STATE;

  constructor(public payload?: any) {}
}

export type Actions = SetState;
