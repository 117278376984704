import { RouterStateSerializer } from '@ngrx/router-store';
import { Params, RouterStateSnapshot } from '@angular/router';

export interface State {
  url: string;
  params: Params;
  queryParams: Params;
}

export class CustomRouterStateSerializer
  implements RouterStateSerializer<State> {
  serialize(routerState: RouterStateSnapshot): State {
    let route = routerState.root;
    let params: any = {};

    while (route.firstChild) {
      route = route.firstChild;

      if (route.outlet === 'primary') {
        params = {
          ...params,
          ...route.params,
        };
      }
    }

    const { url } = routerState;
    const queryParams = routerState.root.queryParams;

    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    return { url, params, queryParams };
  }
}
