import { RouterReducerState } from '@ngrx/router-store';
import { Params } from '@angular/router';
import {
  State as CookieState,
  reducers as cookieReducers,
} from '@local/cookie';
import { ActionReducerMap } from '@ngrx/store';
import { InjectionToken } from '@angular/core';

export interface State extends CookieState {
  routerReducer: RouterReducerState<{
    url: string;
    params: Params;
    queryParams: Params;
    // breadcrumbs: string[];
  }>;
}

export const reducers = {
  ...cookieReducers,
};

export const REDUCER_TOKEN = new InjectionToken<unknown>('RegisteredReducers');
