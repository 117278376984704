import * as Actions from './stable-router.actions';
import { RouterReducerState } from '@ngrx/router-store';
import { State } from './router.state';
export { Actions };

const initialState: RouterReducerState<State> = {
  state: {
    url: '',
    params: {},
    queryParams: {},
  },
  navigationId: 0,
};

export function reducer(
  state = initialState,
  action: Actions.Actions,
): RouterReducerState<State> {
  switch (action.type) {
    case Actions.SET_STATE:
      return action.payload;
    default:
      return state;
  }
}
