import { NgModule, Provider } from '@angular/core';
import {
  BrowserModule,
  BrowserTransferStateModule,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { ENVIRONMENT, REDUCER_TOKEN } from '@local/shared-environment';
import { TransferHttpCacheModule } from '@nguniversal/common';

import { MatButtonModule } from '@angular/material/button';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ProfilePictureModule } from '@local/profile-picture';
import { SWIconsModule } from '@local/icons';
import { InfoBarModule } from '@local/info-bar';
import { NotificationModule } from '@local/notification';
import {
  SkyhookMultiBackendModule,
  createDefaultMultiBackend,
} from '@angular-skyhook/multi-backend';
import { SkyhookDndModule } from '@angular-skyhook/core';
import { HttpClientModule } from '@angular/common/http';

import { SWGraphQLModule } from '@local/graphql';
import { AngularFireModule } from '@angular/fire';
import {
  AngularFireFunctionsModule,
  FUNCTIONS_ORIGIN,
} from '@angular/fire/functions';

import {
  StoreRouterConnectingModule,
  NavigationActionTiming,
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { reducers, metaReducers, CustomRouterStateSerializer } from './state';

import { AppComponent } from './app.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { NotFound404Component } from './not-found-404.component';
import { Error500Component } from './error-500.component';
import { MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { IsLoadingPipeModule } from '@service-work/is-loading';

@NgModule({
  imports: [
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireFunctionsModule,
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'service-work-volunteer' }),
    BrowserTransferStateModule,
    EffectsModule.forRoot([]),
    HttpClientModule,
    InfoBarModule,
    IsLoadingPipeModule,
    MatButtonModule,
    MatProgressBarModule,
    MatToolbarModule,
    MatSidenavModule,
    NotificationModule,
    ProfilePictureModule,
    SkyhookMultiBackendModule,
    SkyhookDndModule.forRoot({
      backendFactory: createDefaultMultiBackend,
    }),
    StoreModule.forRoot(REDUCER_TOKEN, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: !environment.production,
        strictActionImmutability: !environment.production,
      },
    }),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomRouterStateSerializer,
      // navigationActionTiming: NavigationActionTiming.PostActivation,
    }),
    SWIconsModule,
    SWGraphQLModule,
    TransferHttpCacheModule,
    !environment.production
      ? StoreDevtoolsModule.instrument({ maxAge: 25 })
      : [],
  ],
  declarations: [
    AppComponent,
    ToolbarComponent,
    NotFound404Component,
    Error500Component,
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: FUNCTIONS_ORIGIN,
      useValue: environment.uris.functions.startsWith('http://localhost')
        ? environment.uris.functions
        : null,
    },
    { provide: ENVIRONMENT, useValue: environment },
    { provide: REDUCER_TOKEN, useValue: reducers },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' },
    },
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,
      useValue: {
        separatorKeyCodes: [ENTER, COMMA],
      },
    },
  ],
})
export class AppModule {}
