import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFound404Component } from './not-found-404.component';
import { Error500Component } from './error-500.component';

const routes: Routes = [
  {
    path: 'signup',
    loadChildren: './signup/signup.module#SignupModule',
  },
  // {
  //   path: 'registration',
  //   loadChildren: './registration/registration.module#RegistrationModule'
  // },
  // {
  //   path: 'person',
  //   loadChildren: './person/person.module#PersonModule'
  // },
  {
    path: '404',
    component: NotFound404Component,
  },
  {
    path: '500',
    component: Error500Component,
  },
  {
    path: '',
    pathMatch: 'full',
    loadChildren: './home/home.module#HomeModule',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
