import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-500',
  template: `
    <p>
      error-500 works!
    </p>
  `,
  styles: [],
})
export class Error500Component implements OnInit {
  constructor() {}

  ngOnInit() {}
}
