import gql from 'graphql-tag';

export const typeDefs = gql`
  extend type Query {
    formComponent(id: ID!): FormComponent
    questionChain(id: ID!): QuestionChain
    questionChains: [QuestionChain!]!
    questionGroup(id: ID!): QuestionGroup
    questionGroups: [QuestionGroup!]!
    selectQuestion(id: ID!): SelectQuestion
    selectQuestions: [SelectQuestion!]!
    textQuestion(id: ID!): TextQuestion
    textQuestions: [TextQuestion!]!
    booleanQuestion(id: ID!): BooleanQuestion
    booleanQuestions: [BooleanQuestion!]!
  }

  extend type Mutation {
    setFormComponent(id: ID!): FormComponent
    setQuestionChain(id: ID!): QuestionChain
    setQuestionGroup(id: ID!): QuestionGroup
    setSelectQuestion(id: ID!): SelectQuestion
    setTextQuestion(id: ID!): TextQuestion
    setBooleanQuestion(id: ID!): BooleanQuestion
  }

  scalar FormComponentWithPromptVariables
  scalar ValueConstraint

  type FormComponent {
    id: ID!
    templateId: ID!
    order: Int!
  }

  type FormComponentWithPrompt implements FormComponent {
    id: ID!
    templateId: ID!
    order: Int!
    prompt: String
    promptHint: String
    answerHint: String
    variables: FormComponentWithPromptVariables
  }

  type Question implements FormComponentWithPrompt {
    id: ID!
    templateId: ID!
    order: Int!
    prompt: String
    promptHint: String
    answerHint: String
    variables: FormComponentWithPromptVariables
    fieldLabel: String
    valueConstraint: ValueConstraint
    required: Boolean
    visible: Boolean
    logic: String!
  }

  type BooleanQuestion implements Question {
    id: ID!
    templateId: ID!
    order: Int!
    prompt: String
    promptHint: String
    answerHint: String
    variables: FormComponentWithPromptVariables
    fieldLabel: String
    valueConstraint: ValueConstraint
    required: Boolean
    visible: Boolean
    logic: String!
  }

  enum TextQuestionValueType {
    TEXT
    TEXT_LONG
    TEXT_LIST
    DATE
    PHONE_NUMBER
    EMAIL_ADDRESS
    URL
    INTEGER
  }

  type TextQuestion implements Question {
    id: ID!
    templateId: ID!
    order: Int!
    prompt: String
    promptHint: String
    answerHint: String
    variables: FormComponentWithPromptVariables
    fieldLabel: String
    valueConstraint: ValueConstraint
    required: Boolean
    visible: Boolean
    logic: String!
    valueType: TextQuestionValueType
  }

  enum SelectQuestionType {
    SINGLE
    MULTI
  }

  scalar SelectQuestionOption

  type SelectQuestion implements Question {
    id: ID!
    templateId: ID!
    order: Int!
    prompt: String
    promptHint: String
    answerHint: String
    variables: FormComponentWithPromptVariables
    fieldLabel: String
    valueConstraint: ValueConstraint
    required: Boolean
    visible: Boolean
    logic: String!
    selectType: SelectQuestionType!
    options: [SelectQuestionOption!]!
    customOption: Boolean
  }

  type QuestionGroup implements FormComponentWithPrompt {
    id: ID!
    templateId: ID!
    order: Int!
    prompt: String
    promptHint: String
    answerHint: String
    variables: FormComponentWithPromptVariables
    logic: String!
    questions: [Question!]!
  }

  type QuestionChainStep {
    id: ID!
    templateId: ID!

    trigger: ValueConstraint
    dynamic: String
    forEvery: Boolean
    cutoff: Boolean!

    question: FormComponentWithPrompt!
    steps: [QuestionChainStep!]!
  }

  type QuestionChain implements FormComponent {
    id: ID!
    templateId: ID!
    order: Int!
    firstStep: QuestionChainStep!
  }
`;
