import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewContainerRef,
} from '@angular/core';
// import { Store } from '@ngrx/store';
// import { State } from './state';
// import { UserAccountService } from '@local/user-account';
import { Observable, of } from 'rxjs';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from '@angular/router';
import { IsLoadingService } from '@service-work/is-loading';
import { filter, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State, StableRouterReducer } from './state';
// import { MatSidenav, MatSidenavContent } from '@angular/material';
// import { filter, withLatestFrom, map } from 'rxjs/operators';
// import {
//   Router,
//   NavigationStart,
//   NavigationEnd,
//   NavigationCancel,
// } from '@angular/router';
// import { ToolbarService } from './toolbar/toolbar.service';
// import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  // @ViewChild(MatSidenav) sidenav: MatSidenav;
  // @ViewChild(MatSidenavContent, { read: ViewContainerRef })
  sidenavContent: ViewContainerRef;

  public user: any | null;
  public displayNotYouBar = false;
  public menuView: 'unverified' | 'verified' | 'signed in' = 'unverified';

  constructor(
    private router: Router,
    private isLoadingService: IsLoadingService,
    private store: Store<State>,
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(
        filter(
          event =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError,
        ),
        withLatestFrom(this.store.select('routerReducer')),
      )
      .subscribe(([event, routerState]) => {
        // If it's the start of navigation, `add()` a loading indicator
        if (event instanceof NavigationStart) {
          this.isLoadingService.add();
          return;
        }

        // Else navigation has ended, so `remove()` a loading indicator
        this.isLoadingService.remove();

        this.store.dispatch(
          new StableRouterReducer.Actions.SetState(routerState),
        );
      });

    // this.toolbarService.user.subscribe(user => {
    //   if (user) {
    //     this.user = user.person;
    //     this.displayNotYouBar = !user.authenticated;
    //     if (user.authenticated) {
    //       this.menuView = 'signed in';
    //     } else if (user.person.confirmedUserAccount) {
    //       this.menuView = 'verified';
    //     } else {
    //       this.menuView = 'unverified';
    //     }
    //   } else {
    //     this.user = null;
    //   }
    //   this.ref.markForCheck();
    // });
    // this.userService.userAccount$.subscribe(user => {
    //   this.toolbarService.addVerifiedUserAccount(user);
    // });
    // this.isLoading = this.appLoading.isLoading();
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationStart) {
    //     this.appLoading.loading = true;
    //   } else if (event instanceof NavigationEnd) {
    //     this.sidenavContent.element.nativeElement.scrollTo(0, 0);
    //     this.appLoading.loading = false;
    //   } else if (event instanceof NavigationCancel) {
    //     this.appLoading.loading = false;
    //   }
    // });
  }

  toggleMenu() {
    // this.displayNotYouBar = false;
    // this.sidenav.toggle();
    // this.ref.markForCheck();
  }

  // unloadUserAccount() {
  //   this.toolbarService.userNotMe.next();
  //   this.sidenav.close();
  //   this.ref.markForCheck();
  // }

  // signout(sideNav: MatSidenav) {
  //   this.userService.signout();
  //   sideNav.close();
  //   this.ref.markForCheck();
  // }

  // get signinUrl() {
  //   return `${this.signinAppRoot}/signin?redirectTo=${this.currentUrl}`;
  // }

  // get currentUrl() {
  //   return `${environment.uris.volunteerClient}${this.router.url}`;
  // }

  // get signinAppRoot() {
  //   return environment.uris.accountClient;
  // }
}
