import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { SWLogoComponent } from './sw-logo/sw-logo.component';

export { SWLogoComponent };

@NgModule({
  imports: [CommonModule, MatIconModule],
  exports: [MatIconModule, SWLogoComponent],
  declarations: [SWLogoComponent],
})
export class SWIconsModule {
  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {
    const icons = [
      ['google', 'assets/icons/logos/google-color.svg'],
      ['google-black', 'assets/icons/logos/google-black.svg'],
      ['twitter', 'assets/icons/logos/twitter-color.svg'],
      ['twitter-black', 'assets/icons/logos/twitter-black.svg'],
      ['facebook', 'assets/icons/logos/facebook-color.svg'],
      ['facebook-black', 'assets/icons/logos/facebook-black.svg'],
    ];

    icons.forEach(icon => this.registerSvgIcon(...(icon as [string, string])));

    const namespacedIcons = [
      ['logo', 'assets/icons/logos/service-work-regular-color.svg', 'sw'],
      ['logo-black', 'assets/icons/logos/service-work-regular-black.svg', 'sw'],
      ['icon', 'assets/icons/logos/service-work-small-color.svg', 'sw'],
      ['icon-black', 'assets/icons/logos/service-work-small-black.svg', 'sw'],
      ['role', 'assets/icons/role.svg', 'lib'],
      ['role-add', 'assets/icons/role-add.svg', 'lib'],
      ['role-shift', 'assets/icons/role-shift.svg', 'lib'],
      ['role-remove', 'assets/icons/role-remove.svg', 'lib'],
      ['calendar-day', 'assets/icons/calendar-day.svg', 'lib'],
      ['calendar-week', 'assets/icons/calendar-week.svg', 'lib'],
      ['calendar-month', 'assets/icons/calendar-month.svg', 'lib'],
      ['calendar-list', 'assets/icons/calendar-list.svg', 'lib'],
      ['list-add', 'assets/icons/list-add.svg', 'lib'],
      ['move', 'assets/icons/move.svg', 'lib'],
      ['multi-owner', 'assets/icons/multi-owner.svg', 'lib'],
      ['multi-owner-hidden', 'assets/icons/multi-owner-hidden.svg', 'lib'],
      ['shift', 'assets/icons/shift.svg', 'lib'],
      ['shift-add', 'assets/icons/shift-add.svg', 'lib'],
      ['edit-signup', 'assets/icons/edit-signup.svg', 'lib'],
    ];

    namespacedIcons.forEach(icon =>
      this.registerSvgIcon(...(icon as [string, string, string])),
    );
  }

  registerSvgIcon(name: string, url: string, namespace?: string) {
    if (namespace) {
      this.iconRegistry.addSvgIconInNamespace(
        namespace,
        name,
        this.sanitizer.bypassSecurityTrustResourceUrl(url),
      );
    } else {
      this.iconRegistry.addSvgIcon(
        name,
        this.sanitizer.bypassSecurityTrustResourceUrl(url),
      );
    }
  }
}
