import { NgModule, PLATFORM_ID, Inject, Optional } from '@angular/core';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
// import { ENVIRONMENT, IEnvironment } from '@local/environment';
// import { CookieService } from '@local/ngx-cookie';
import { isPlatformServer } from '@angular/common';
// import { withClientState } from 'apollo-link-state';

// Apollo
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import {
  InMemoryCache,
  NormalizedCacheObject,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { ApolloClientOptions } from 'apollo-client';
import { WebSocketLink } from 'apollo-link-ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { getOperationAST } from 'graphql';
import { typeDefs } from './client.schema';
import { ENVIRONMENT, IEnvironment } from '@local/shared-environment';
import { Apollo } from './apollo.service';
import { dataIdFromObject } from '@local/graphql/resolvers';

export { HttpClientModule, HttpLinkModule };

// an introspection fragment match is necessary if the graphql
// schema includes interfaces / union types. See apollo's documentation
// const introspectionQueryResultData = require('../fragment-types.json');

// import * as introspectionQueryResultData from '../fragment-types.json';

// const fragmentMatcher = new IntrospectionFragmentMatcher({
//   introspectionQueryResultData,
// });

const STATE_KEY = makeStateKey<any>('apollo.state');

@NgModule({
  imports: [HttpClientModule, HttpLinkModule],
  exports: [HttpClientModule, HttpLinkModule],
})
export class SWGraphQLModule {
  private cache: InMemoryCache;

  constructor(
    private transferState: TransferState,
    apollo: Apollo,
    httpLinkConstructor: HttpLink,
    // cookieService: CookieService,
    @Inject(ENVIRONMENT) environment: IEnvironment,
    @Inject(PLATFORM_ID) platformId: any,
  ) {
    const HTTP_URI = environment.uris.graphql;
    // const WS_URI = 'ws://localhost:8080/v1/graphql'; // environment.uris.api.graphql;

    this.cache = new InMemoryCache({
      // fragmentMatcher,
      addTypename: true,
      dataIdFromObject,
      freezeResults: !environment.production,
      cacheRedirects: {
        Query: {
          // user: (_, args, { getCacheKey }) => getCacheKey({ __typename: 'UserAccount', id: "ROOT_QUERY.user" }),
          // person: (_, args, { getCacheKey }) => getCacheKey({ __typename: 'Person', id: args.personId }),
          // organization: (_, args, { getCacheKey }) => getCacheKey({ __typename: 'Organization', id: args.organizationId }),
          // Form: (_, args, context) => {
          //   console.log('form redirect', _, args, context);
          //   return context.getCacheKey({ __typename: 'Form', id: args.formId });
          // },
          // signup: (_, args, { getCacheKey }) => {
          //   if (args.signupId) {
          //     return getCacheKey({ __typename: 'Signup', id: args.signupId })
          //   }
          // },
        },
      },
    });

    // let headers: { [name: string]: string } = {};
    // // get the authentication token from local storage if it exists
    // const token = this.cookieService.get('auth_token');

    // if (token) headers.authorization = token;

    const link = httpLinkConstructor.create({
      uri: HTTP_URI,
      // headers: new HttpHeaders(headers),
      // includeExtensions: true,
      withCredentials: true,
    });

    // const link = new WebSocketLink(
    //   new SubscriptionClient(WS_URI, {
    //     reconnect: true,
    //   }),
    // );

    // const extensionsLink = new ApolloLink((operation, forward) => {
    //   operation.extensions = {
    //     perspective,
    //   };

    //   return forward!(operation);
    // });

    // const authLink = new ApolloLink((operation, forward) => {
    //   const token = cookieService.get('XSRF-TOKEN')

    //   if (token) {
    //     operation.setContext(({ headers }) => ({ headers: {
    //       ...headers,
    //       ['X-XSRF-TOKEN']: token
    //     }}));
    //   }

    //   return forward!(operation);
    // });

    // const stateLink = withClientState({
    //   cache: this.cache,
    //   resolvers: {
    //     // Query: {
    //     //   form: (obj, args, ctx) => {
    //     //     console.log('root object', obj, args, ctx);
    //     //   },
    //     // },
    //   },
    // });

    // const link = ApolloLink.from([extensionsLink, authLink, stateLink, httpLink]);
    // const link = ApolloLink.from([swLink]);

    // formComponent(id: ID!): FormComponent
    // questionChain(id: ID!): QuestionChain
    // questionGroup(id: ID!): QuestionGroup
    // selectQuestion(id: ID!): SelectQuestion
    // textQuestion(id: ID!): TextQuestion
    // booleanQuestion(id: ID!): BooleanQuestion

    const apolloOptions: ApolloClientOptions<NormalizedCacheObject> = {
      link,
      cache: this.cache,
      typeDefs,
      assumeImmutableResults: true,
      // resolvers: {
      //   Query: {
      //     questionChain: (obj, args, { cache, getCacheKey }, info) => {
      //       const id = getCacheKey({ __typename: 'QuestionChain', id: args.id })

      //       const fragment = gql`
      //         fragment completeTodo on TodoItem {
      //           completed
      //         }
      //       `;

      //       const todo = cache.readFragment({ fragment, id });
      //       const data = { ...todo, completed: !todo.completed };
      //       cache.writeData({ id, data });
      //       return null;
      //     },
      //     questionGroup: () => {},
      //     selectQuestion: () => {},
      //     textQuestion: () => {},
      //     booleanQuestion: () => {},
      //   },
      // },
    };

    if (isPlatformServer(platformId)) {
      apolloOptions.ssrForceFetchDelay = 100;
      apollo.create(apolloOptions);
      this.serializeState();
    } else {
      apolloOptions.ssrMode = true;
      this.initializeState();
      apollo.create(apolloOptions);
    }
  }

  private serializeState(): void {
    this.transferState.onSerialize(STATE_KEY, () => this.cache.extract());
  }

  private initializeState(): void {
    const state = this.transferState.get<NormalizedCacheObject | null>(
      STATE_KEY,
      null,
    );

    if (state) {
      this.cache.restore(state);
    }
  }
}
